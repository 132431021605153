
// src/pages/ProviderListingPage.js
import React from 'react';
import ProviderList from '../components/ServiceProvider/ServiceProviderList';
import SEO from '../components/Seo';

const ProviderListingPage = () => {
  return (
    <>
      <SEO
        title="Find Service Providers"
        description="Browse our directory of verified Sikh religious service providers"
      />
      <ProviderList />
    </>
  );
};

export default ProviderListingPage;