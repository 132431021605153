// src/components/Layout/Mission.js
import React from 'react';

const Mission = () => {
  return (
    <section className="bg-gradient-to-r from-orange-100 to-yellow-100 py-16">
      <div className="max-w-4xl mx-auto px-6">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">
          Our Mission at GuruSewa
        </h2>
        
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4 text-orange-600">Our Vision</h3>
            <p className="text-gray-700">
              To create a trusted bridge between the Sikh community and dedicated religious service providers, 
              fostering spiritual growth and preserving our rich traditions across the globe.
            </p>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold mb-4 text-orange-600">Our Purpose</h3>
            <p className="text-gray-700">
              To simplify the process of connecting Sikh families with verified Ragis, Kirtaniyas, 
              Dhadhis, and Kathawachaks, ensuring authentic spiritual services for all occasions.
            </p>
          </div>
        </div>

        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h3 className="text-xl font-semibold mb-4 text-orange-600">Our Commitment</h3>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="text-center">
              <div className="text-3xl mb-2">🔍</div>
              <h4 className="font-semibold mb-2">Verified Providers</h4>
              <p className="text-sm text-gray-600">
                Thorough background checks for your peace of mind
              </p>
            </div>
            <div className="text-center">
              <div className="text-3xl mb-2">🤝</div>
              <h4 className="font-semibold mb-2">Community First</h4>
              <p className="text-sm text-gray-600">
                Supporting both service providers and seekers
              </p>
            </div>
            <div className="text-center">
              <div className="text-3xl mb-2">💫</div>
              <h4 className="font-semibold mb-2">Preserving Tradition</h4>
              <p className="text-sm text-gray-600">
                Maintaining the sanctity of our religious services
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;

