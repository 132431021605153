// src/components/Layout/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-6 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          <div>
            <h3 className="text-xl font-bold mb-4">GuruSewa</h3>
            <p className="text-gray-400 text-sm">
              Connecting the Sikh community with verified religious service providers
            </p>
          </div>
          
          <div>
            <h4 className="font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2 text-gray-400">
              <li><Link to="/" className="hover:text-orange-500">Home</Link></li>
              <li><Link to="/providers" className="hover:text-orange-500">Find Providers</Link></li>
              <li><Link to="/register" className="hover:text-orange-500">Register as Provider</Link></li>
            </ul>
          </div>
          
          <div>
            <h4 className="font-semibold mb-4">Services</h4>
            <ul className="space-y-2 text-gray-400">
              <li><Link to="/providers" className="hover:text-orange-500">Ragi Services</Link></li>
              <li><Link to="/providers" className="hover:text-orange-500">Kirtaniya Services</Link></li>
              <li><Link to="/providers" className="hover:text-orange-500">Dhadhi Services</Link></li>
              <li><Link to="/providers" className="hover:text-orange-500">Kathawachak Services</Link></li>
            </ul>
          </div>
          
          <div>
            <h4 className="font-semibold mb-4">Contact</h4>
            <ul className="space-y-2 text-gray-400">
              <li>Email: gurusewa00@gmail.com</li>
              <li>Support Hours: 9 AM - 5 PM EST</li>
            </ul>
          </div>
        </div>
        
        <div className="border-t border-gray-800 pt-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="text-gray-400 text-sm">
              © {currentYear} GuruSewa. All rights reserved.
            </div>
            <div className="text-gray-400 text-sm md:text-right space-x-4">
              <Link to="/privacy" className="hover:text-orange-500">Privacy Policy</Link>
              <Link to="/terms" className="hover:text-orange-500">Terms of Service</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;