// frontend/src/services/api.js
import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000
});

export const getProviders = async (params = {}) => {
  try {
    const response = await api.get('/providers', { params });
    
    // Handle the updated response structure
    if (!response.data || !response.data.data) {
      throw new Error('Invalid response format');
    }

    return response.data; 

  } catch (error) {
    console.error('API Error:', error);
    throw new Error('Unable to load providers. Please try again.');
  }
};
