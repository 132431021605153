// src/pages/TermsPage.js
import React from 'react';
import { Helmet } from 'react-helmet-async';

const TermsPage = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions - GuruSewa</title>
      </Helmet>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8">Terms and Conditions</h1>
        
        <div className="prose max-w-none">
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Agreement to Terms</h2>
            <p>
              By accessing or using GuruSewa, you agree to be bound by these Terms and Conditions.
              If you disagree with any part of these terms, you may not access the service.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Service Provider Listings</h2>
            <p>Service providers listed on GuruSewa:</p>
            <ul className="list-disc ml-6 mb-4">
              <li>Must provide accurate and truthful information</li>
              <li>Are responsible for maintaining current contact information</li>
              <li>Must conduct themselves professionally and respectfully</li>
              <li>Should honor their commitments to service seekers</li>
              <li>Must comply with all applicable laws and regulations</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">User Responsibilities</h2>
            <p>Users of GuruSewa agree to:</p>
            <ul className="list-disc ml-6 mb-4">
              <li>Provide accurate information when contacting service providers</li>
              <li>Treat service providers with respect</li>
              <li>Honor scheduled appointments and commitments</li>
              <li>Communicate professionally</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Service Quality</h2>
            <p>
              While GuruSewa verifies basic information about service providers, we do not:
            </p>
            <ul className="list-disc ml-6 mb-4">
              <li>Guarantee the quality of services provided</li>
              <li>Act as a party in any transaction between users and providers</li>
              <li>Mediate disputes between users and providers</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Limitation of Liability</h2>
            <p>
              GuruSewa serves as a platform connecting service providers with users. 
              We are not responsible for:
            </p>
            <ul className="list-disc ml-6 mb-4">
              <li>The quality of services provided</li>
              <li>Disputes between users and service providers</li>
              <li>Any damages or losses resulting from using our service</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Modifications</h2>
            <p>
              We reserve the right to modify these terms at any time. 
              Continued use of the platform after changes constitutes acceptance 
              of the modified terms.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Contact</h2>
            <p>
              For questions about these Terms and Conditions, please contact us at:{' '}
              <a href="mailto:gurusewa00@gmail.com" className="text-saffron-600 hover:text-saffron-700">
                gurusewa00@gmail.com
              </a>
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default TermsPage;