import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Mission from '../components/Layout/Mission';

const HomePage = () => {
  const GOOGLE_FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSfDps6WZ6XbXndlPEAskijz-lJU-RdwfgWLmnMunT1lSpyj8w/viewform';

  return (
    <>
      <Helmet>
        <title>GuruSewa - Connect with Sikh Religious Service Providers</title>
        <meta
          name="description"
          content="Find verified Ragis, Kirtaniyas, Dhadhis, and Kathawachaks for your religious ceremonies and events."
        />
        <meta
          name="keywords"
          content="Sikh services, Ragi, Kirtaniya, Dhadhi, Kathawachak, religious services"
        />
      </Helmet>

      {/* Hero Section */}
      <div className="bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block">Find Trusted</span>
              <span className="block text-indigo-600">Sikh Religious Service Providers</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Connect with verified Ragis, Kirtaniyas, Dhadhis, and Kathawachaks for your religious ceremonies and events.
            </p>
            <div className="mt-8 max-w-2xl mx-auto sm:flex sm:justify-center md:mt-12 gap-6">
              <div className="rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105">
                <Link
                  to="/providers"
                  className="w-full flex items-center justify-center px-10 py-4 text-lg font-bold rounded-lg text-white bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 md:py-5 md:text-xl md:px-12"
                >
                  Find Providers
                </Link>
              </div>
              <div className="mt-4 sm:mt-0 rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105">
                <a
                  href={GOOGLE_FORM_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full flex items-center justify-center px-10 py-4 text-lg font-bold rounded-lg text-white bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 md:py-5 md:text-xl md:px-12"
                >
                  List Your Services
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mission Section */}
      <Mission />

      {/* CTA Section */}
      <div className="bg-indigo-600">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
            <span className="block">Ready to serve the community?</span>
            <span className="block text-indigo-200">Register as a service provider today.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 gap-4">
            <div className="rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105">
              <a
                href={GOOGLE_FORM_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center justify-center px-8 py-4 text-lg font-bold rounded-lg text-white bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700"
              >
                Register Now
              </a>
            </div>
            <div className="rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105">
              <Link
                to="/providers"
                className="inline-flex items-center justify-center px-8 py-4 text-lg font-bold rounded-lg text-white bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700"
              >
                Browse Providers
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;