// src/constants/serviceTypes.js
export const PROVIDER_TYPES = [
    { value: 'ragi', label: 'Ragi' },
    { value: 'kirtaniya', label: 'Kirtaniya' },
    { value: 'dhadhi', label: 'Dhadhi' },
    { value: 'kathawachak', label: 'Kathawachak' }
  ];
  
  export const SERVICES = {
    kirtan: 'Kirtan',
    path: 'Path',
    katha: 'Katha',
    akhandPath: 'Akhand Path',
    wedding: 'Wedding Ceremonies',
    gurmathClass: 'Gurmath Classes',
    sehajPath: 'Sehaj Path',
    sukhmaniSahib: 'Sukhmani Sahib Path',
    chaupaiSahib: 'Chaupai Sahib Path',
    japjiSahib: 'Japji Sahib Path',
    rehrasSahib: 'Rehras Sahib Path',
    sanskar: 'Sanskar Ceremonies'
  };
  
  export const EXPERIENCE_LEVELS = [
    { value: '0-1', label: 'Less than 1 year' },
    { value: '1-3', label: '1-3 years' },
    { value: '3-5', label: '3-5 years' },
    { value: '5-10', label: '5-10 years' },
    { value: '10+', label: 'More than 10 years' }
  ];
  
  export const SORT_OPTIONS = [
    { value: 'recent', label: 'Most Recent' },
    { value: 'name', label: 'Name A-Z' },
    { value: 'location', label: 'Location' },
    { value: 'experience', label: 'Experience' }
  ];
  
  export const SERVICE_CATEGORIES = [
    {
      category: 'Daily Services',
      services: ['kirtan', 'path', 'katha']
    },
    {
      category: 'Special Occasions',
      services: ['wedding', 'akhandPath', 'sanskar']
    },
    {
      category: 'Education',
      services: ['gurmathClass']
    },
    {
      category: 'Path Services',
      services: ['sehajPath', 'sukhmaniSahib', 'chaupaiSahib', 'japjiSahib']
    }
  ];
  
  // Helper function to get service label from value
  export const getServiceLabel = (serviceKey) => {
    return SERVICES[serviceKey] || serviceKey;
  };
  
  // Helper function to get all services in a category
  export const getCategoryServices = (categoryName) => {
    const category = SERVICE_CATEGORIES.find(cat => cat.category === categoryName);
    return category ? category.services.map(service => ({
      value: service,
      label: SERVICES[service]
    })) : [];
  };
  
  // Helper function to check if a service exists
  export const isValidService = (serviceKey) => {
    return Object.keys(SERVICES).includes(serviceKey);
  };
  
  // For form validation
  export const SERVICE_VALIDATION = {
    minServices: 1,
    maxServices: 10,
    message: 'Please select at least one service and no more than 10 services'
  };