// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import MainLayout from './components/Layout/MainLayout';
import { Analytics } from "@vercel/analytics/react"
// Pages
import HomePage from './pages/HomePage';
import ProviderListingPage from './pages/ProviderListingPage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';
import NotFoundPage from './pages/NotFoundPage';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <MainLayout>
        <Analytics/>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/providers" element={<ProviderListingPage />} />
            {/*  <Route path="/about" element={<AboutPage />} /> */}
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/terms" element={<TermsPage />} />
          </Routes>
        </MainLayout>
      </Router>
    </HelmetProvider>
  );
}

export default App;