// src/components/SEO/index.js
import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({ 
  title, 
  description, 
  keywords, 
  ogImage = 'https://Gurusewa.com/og-image.jpg',
  canonicalUrl 
}) => {
  const siteTitle = 'Gurusewa - Connect with Verified Sikh Religious Service Providers';
  const fullTitle = title ? `${title} | ${siteTitle}` : siteTitle;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
      
      {/* Canonical URL */}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      
      {/* Structured Data */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Gurusewa",
            "url": "https://Gurusewa.com",
            "logo": "https://Gurusewa.com/logo.png",
            "description": "${description}",
            "sameAs": [
              "https://facebook.com/Gurusewa",
              "https://twitter.com/Gurusewa",
              "https://instagram.com/Gurusewa"
            ]
          }
        `}
      </script>
    </Helmet>
  );
};
export default SEO